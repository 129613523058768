import "../styles/pages/invoice.scss"
import React from "react"
import SEO from "../components/seo";
import InvoiceOrderSection from "../components/Invoice/InvoiceOrderSection";
import {graphql} from "gatsby";

const OrderForm = ({data}) => {
  const accessoriesList = data.hubbleapi.hubbleAccessoryProducts

  const urlParams = (typeof window !== 'undefined') ? new URLSearchParams(window?.location.search) : null
  const invoiceId = urlParams?.get('invoice_id')


  return (
    <div className="invoice-page">
      <SEO title="Invoice" noIndex={true} />
      <InvoiceOrderSection hubbleAccessories={accessoriesList} id={invoiceId} />
    </div>
  )
}

export default OrderForm

export const query = graphql`
  query {
    hubbleapi {
      hubbleAccessoryProducts{
        title
        handle
        description
        shopifyProductId
        variantId
        price
        productImage1
        productImage2
        id
      }
    }
  }
`
